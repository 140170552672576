<template>
  <div class="pa-4">
    <v-subheader>Информация</v-subheader>
    <v-card v-if="false" class="pa-2" elevation="1">
      Client info
      <v-card-text>
        text
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    chatId: { type: null, default: null }
  }
}
</script>

<style scoped>

</style>
