import { ChatMessageEntity } from './chat-message-entity'
import { BaseEntity } from '../common/base-entity'

export class ChatEntity extends BaseEntity {
  constructor(attributes) {
    super()

    this.chatId = null
    this.owner = null
    this.visitor = null
    this._messages = []

    this.setAttributes(attributes)
  }

  set messages(messages) {
    if (!Array.isArray(messages || null)) return

    for (const message of messages) {
      this._messages.push(new ChatMessageEntity(message))
    }
  }
  get messages() {
    return this._messages
  }
}
