<template>
  <div
    :class="{ 'd-flex flex-row-reverse': !me }"
  >
    <v-chip
      :color="me ? 'primary' : 'blue-grey lighten-4'"
      style="height: auto; white-space: normal; word-break: break-all;"
      class="pa-4 mb-2 app-message"
      :class="{ 'my-message': me, 'other-message': !me }"
    >
      {{ text }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    me: { type: Boolean, default: false },
    text: { type: String, default: '' }
  }
}
</script>

<style scoped>
  .my-message {
    border-radius: 10px 10px 10px 0;
  }
  .other-message {
    border-radius: 10px 10px 0 10px;
  }
</style>
