<template>
  <div>
    <v-textarea
      ref="messageInput"
      v-model="text"
      outlined
      auto-grow
      hide-details
      rows="1"
      autofocus
      :disabled="disabled || loading"
      :label="inputLabel"
      @keyup.enter="onSendClick"
      @keydown.enter.stop.prevent="nope"
      @keyup.ctrl.enter="appendNewLine"
    />
    <v-btn
      icon
      x-large
      :disabled="disabled || loading"
      :loading="loading"
      @click="onSendClick"
    >
      <v-icon color="blue-grey">mdi-send</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    chatEntity: { type: null, default: null },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      text: '',
      loading: false
    }
  },
  computed: {
    inputLabel() {
      return this.disabled
        ? 'Вы офлайн. Подключитесь для отправки сообщений'
        : this.loading ? 'Идет отправка сообщения ...' : 'Введите сообщение...'
    }
  },
  methods: {
    ...mapActions({
      sendMessage: 'chat/sendMessage'
    }),
    async onSendClick(e) {
      if (e.ctrlKey) return
      const text = this.text.trim()
      if (!text) return

      const message = {
        chatId: this.chatEntity.chatId,
        message: { type: 'text', body: { text }}
      }

      this.loading = true
      try {
        await this.sendMessage(message)
        this.text = ''
        this.$emit('message:send', text)
      } catch (e) {
        this.$toast.error('Не удалось отправить сообщение')
      } finally {
        this.loading = false
        this.$nextTick(() => {
          this.$refs.messageInput.focus()
        })
      }
    },
    appendNewLine(e) {
      if (!e.ctrlKey) return
      this.text += String.fromCharCode(10)
    },
    nope() {}
  }
}
</script>

<style scoped>

</style>
