<template>
  <div class="fill-height">
    <div v-if="chatEntity" class="d-flex fill-height flex-column">
      <chat-header :title="chatHeader" />
      <chat-messages
        :messages="messages"
        :my-user-id="chatEntity.visitor"
        class="flex-shrink-1 flex-grow-1 pa-4"
        @message:readall="resetChatUnread(chatId)"
      />
      <chat-toolbar
        :disabled="!connected"
        class="d-flex flex-shrink-0 flex-grow-0 pa-2"
        style="min-height: 64px;"
        :chat-entity="chatEntity"
      />
    </div>
  </div>
</template>

<script>
import ChatMessages from './chat/chat-messages'
import ChatToolbar from './chat/chat-toolbar'
import ChatHeader from './chat/chat-header'
import { ChatEntity } from '@cpanel/entities/chat/chat-entity'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: { ChatHeader, ChatToolbar, ChatMessages },
  props: {
    chatId: { type: String, default: null }
  },
  data() {
    return {
      loading: false,
      chatEntity: null,
      chatHeader: '',
      messages: []
    }
  },
  computed: {
    ...mapGetters({
      getChat: 'chat/getChat',
      connected: 'commutator/connected'
    }),
    chatData() {
      return this.chatId ? this.getChat(this.chatId) : null
    }
  },
  watch: {
    chatData: {
      handler(val) {
        this.setChatEntity(val)
      },
      deep: true
    },
    connected(connected) {
      if (connected) {
        this.queryMessages({ chatId: this.chatId, query: { limit: 20 }})
      }
    }
  },
  mounted() {
    this.setChatEntity(this.chatData, true)
  },
  methods: {
    ...mapActions({
      queryMessages: 'chat/queryMessages',
      resetChatUnread: 'chat/resetUnreadCount'
    }),
    setChatEntity(chatData, queryMessages = false) {
      if (!chatData) {
        this.chatEntity = null
        this.messages = []
        return
      }
      this.chatEntity = new ChatEntity(chatData)
      if (queryMessages && this.connected) {
        this.queryMessages({ chatId: this.chatId, query: { limit: 20 }})
      }
      this.messages = chatData ? (chatData.messages || []).slice() : []
      const user = chatData.members.find(member => (member.type === 'visitor'))
      this.chatHeader = (user || {}).nickname || 'Аноним'
    }
  }
}
</script>

<style scoped>

</style>
