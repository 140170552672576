<template>
  <div>
    <v-subheader>{{ title }}</v-subheader>
    <v-divider />
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' }
  }
}
</script>

<style scoped>

</style>
