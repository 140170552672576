import { ChatMemberEntity } from './chat-member-entity'
import { BaseEntity } from '../common/base-entity'

export const MESSAGE_TYPE = {
  text: 'text',
  system: 'system'
}

export class ChatMessageEntity extends BaseEntity {
  constructor(attributes) {
    super()

    this.messageId = null
    this.text = null
    this.type = null
    this.date = null
    this.text = null
    this._from = null
    this._to = null

    this.setAttributes(attributes)
  }

  get from() {
    return this._from
  }

  set from(memberData) {
    this._from = memberData instanceof ChatMemberEntity ? memberData : new ChatMemberEntity(memberData)
  }

  get to() {
    return this._to
  }

  set to(memberData) {
    this._to = memberData instanceof ChatMemberEntity ? memberData : new ChatMemberEntity(memberData)
  }
}
