<template>
  <v-container fluid>
    <v-row class="justify-center fill-height">
      <v-col cols="auto" class="d-flex col-sm-8 col-md-6 text-center align-center justify-center">
        <div class="">
          <div>
            <div class="pa-4">
              <v-icon size="96" class="primary--text">mdi-message-outline</v-icon>
            </div>
            <h3>Чатов пока нет</h3>
            <p class="pt-6 body-2">
              Здесь появятся чаты когда кто-то из поестителей вам напишет
            </p>
          </div>
          <v-btn
            color="primary"
            :disabled="$apollo.loading"
            :loading="$apollo.loading"
            @click="$emit('refresh')"
          >
            Обновить
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
