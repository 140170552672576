<template>
  <div class="d-flex fill-height">
    <div class="flex-shrink-1 flex-grow-1">
      <v-slide-x-transition appear>
        <chat :chat-id="chatId" />
      </v-slide-x-transition>
    </div>
    <div
      class="flex-shrink-0 flex-grow-0 d-sm-block d-none blue-grey lighten-5"
      style="width: 320px;"
    >
      <chat-right-panel :chat-id="chatId" />
    </div>
  </div>
</template>

<script>
import ChatRightPanel from './components/right-panel'
import Chat from './components/chat'

export default {
  components: { Chat, ChatRightPanel },
  props: {
    chatId: { type: null, default: null }
  }
}
</script>

<style scoped>

</style>
