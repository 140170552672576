<template>
  <div class="d-flex" style="height: 100%">
    <template v-if="loading || chats && chats.length">
      <v-navigation-drawer
        permanent
        :mini-variant="isMobile"
        mini-variant-width="64px"
        class="chat-navi blue-grey lighten-5 flex-shrink-0"
      >
        <chat-list
          :chats="chats"
          header="Последние чаты"
          :show-header="!isMobile"
        />
        <template v-slot:append>
          <div class="pa-4">
            <v-btn
              :icon="isMobile"
              block
              small
              plain
              @click="onRefreshClick"
            >
              <v-icon v-if="isMobile">mdi-dots-horizontal</v-icon>
              <template v-else>Обновить</template>
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
      <div class="flex-grow-1 flex-shrink-1">
        <router-view :key="$route.fullPath + '#chat'" />
      </div>
    </template>
    <chat-starter v-else @refresh="refresh" />
  </div>
</template>

<script>
import ChatList from './components/chat-list'
import ChatStarter from './components/chat-starter'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: { ChatList, ChatStarter },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      chats: 'chat/chats'
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    ...mapActions({ queryChatList: 'chat/queryChatList' }),
    async onRefreshClick() {
      try {
        await this.queryChatList()
        this.$toast.success('Данные успешно обновлены')
      } catch (e) {
        this.$toast.success('Ошибка при обновлении данных')
      }
    },
    async refresh() {
      this.loading = true
      try {
        await this.queryChatList()
      } catch (e) {
        this.$toast.error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
  .chat-navi >>> .v-navigation-drawer__content {
    height: 400px;
    flex: 1 1 auto;
  }
</style>
