import { BaseEntity } from '../common/base-entity'

export const USER_TYPE = {
  visitor: 'visitor',
  agent: 'agent'
}

export class ChatMemberEntity extends BaseEntity {
  constructor(attributes) {
    super()

    this.userId = null
    this.nickname = null
    this.avatar = null
    this.type = null

    this.setAttributes(attributes)
  }
}
