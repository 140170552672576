<template>
  <v-list shaped subheader>
    <v-subheader v-if="showHeader">{{ header }}</v-subheader>
    <v-list-item-group v-model="selectedIndex">
      <v-list-item
        v-for="chat in chatsVal"
        :key="`chat-${chat.chatId}`"
        link
        :to="{ name: 'chat-view', params: { chatId: chat.chatId }}"
        two-line
      >
        <v-list-item-avatar>
          <v-img :src="getVisitorUser(chat).avatar" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ getVisitorUser(chat).nickname }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ chat.updatedAt | formatDate(DATE_FORMAT.FROM_NOW) }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action v-if="chat.$unreadCount">
          <v-chip color="green" small>{{ chat.$unreadCount }}</v-chip>
        </v-list-item-action>

      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { DATE_FORMAT } from '@cpanel/filters/format-date'

export default {
  props: {
    header: { type: String, default: 'Последние чаты' },
    showHeader: { type: Boolean, default: true },
    chats: { type: null, default: () => ([]) }
  },
  data() {
    return {
      chatsVal: this.chats,
      selectedIndex: null,
      DATE_FORMAT
    }
  },
  watch: {
    chats: {
      handler(val) {
        this.setChats(val)
      },
      deep: true
    }
  },
  methods: {
    setChats(val) {
      this.chatsVal = val
    },
    getVisitorUser(chat) {
      const user = chat.members.find(member => (member.type === 'visitor'))
      return { userId: null, nickname: null, avatar: null, ...(user || {}) }
    }
  }
}
</script>

<style scoped>
</style>
