export class BaseEntity {
  _hasSetter(attr) {
    const proto = Object.getPrototypeOf(this)
    return typeof (Object.getOwnPropertyDescriptor(proto, attr) || {}).set === 'function'
  }

  setAttributes(attributes) {
    for (const attr in attributes) {
      if (this.hasOwnProperty(attr) || this._hasSetter(attr)) {
        this[attr] = attributes[attr]
      }
    }
  }
}
