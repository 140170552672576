<template>
  <div ref="messages" style="height: 1px; overflow-y: scroll;">
    <chat-message
      v-for="message in messages"
      :key="`message-${message.messageId}`"
      :me="myUserId === message.fromUserId"
      :text="message.body.text"
    />
    <div
      v-if="(messages || []).length"
      v-intersect="onLastMessageVisible"
      style="height: 10px; width: 10px"
    ></div>
    <div
      v-if="!(messages || []).length"
      class="d-flex fill-height justify-center align-center"
    >
      <v-subheader class="grey--text">Сообщений пока нет</v-subheader>
    </div>
  </div>
</template>

<script>
import ChatMessage from './chat-single-message'

export default {
  components: { ChatMessage },
  props: {
    messages: { type: null, default: [] },
    myUserId: { type: String, default: '' }
  },
  watch: {
    messages: {
      handler() {
        this.scrollDown()
      },
      deep: true
    }
  },
  mounted() {
    this.scrollDown()
  },
  methods: {
    scrollDown() {
      this.$emit('message:readall')
      setTimeout(() => {
        const container = this.$refs['messages']
        container.scrollTop = container.scrollHeight
      }, 500)
    },
    onLastMessageVisible(entries) {
      if (entries[0].isIntersecting) {
        this.$emit('message:readall')
      }
    }
  }
}
</script>

<style scoped>

</style>
